import { render, staticRenderFns } from "./patentList.vue?vue&type=template&id=6fac889b&scoped=true&"
import script from "./patentList.vue?vue&type=script&lang=js&"
export * from "./patentList.vue?vue&type=script&lang=js&"
import style0 from "./patentList.vue?vue&type=style&index=0&id=6fac889b&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fac889b",
  null
  
)

export default component.exports